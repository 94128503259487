import React, { useState, useEffect, useEffectffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Flickity from 'react-flickity-component'

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"
import LiveChat from "../components/Home/livechat"

const getRandomOrder = (arr) => {
  const clone = arr.slice()
  const ret = []
  while (clone.length > 0) {
    const obj = clone.splice(Math.floor(Math.random() * clone.length), 1)[0]
    ret.push(Object.assign({}, obj))
  }
  return ret.slice(0, 3)
}

const PortfolioSinglePage = (props) => {
  const portfolioInfo = props.data.singlePortfolio
  const SEOdata = props.data.singlePortfolio.seoData
  const Schemadata = props.data.singlePortfolio.seoData.schema
  const Hreflang = props.data.singlePortfolio.seoData.hreflang
  const { edges: allPortfolios } = props.data.allportf

  const [morePortfolio, setMorePortfolio] = useState([])
  let flkty = null;
  const flickityOptions = {
    prevNextButtons: false,
    draggable: true,
    cellAlign: 'left',
    pageDots: false,
    wrapAround: true,
    contain: true,
    autoPlay: 3000,
  }

  useEffect(() => {
    const newarr = getRandomOrder(allPortfolios)
    setMorePortfolio(newarr)

  }, [])

  useEffect(() => {

    const updateDimensions = () => {
      var s_div = document.querySelector('.mobile-screens-carousel .flickity-slider');
      if (s_div !== null) {
        flkty.on("select", () => {
          var slideNumber = flkty.selectedIndex + 1;
          if (slideNumber === 1) {
            s_div.style.transform = "translateX(0px)"
          }
        })
      }
    }

    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);

  }, [flkty]);
  const otherPagesGallery = (pageData) => {
    const array = pageData.split(',')
    return (
      <>
        <h3 className="pb-2 lg:pb-4 font-poppings-bold text-term-primary text-rp3 lg:text-h3">{array[0]}</h3>
        <img src={array[1]} alt="DecodeUp portfolio Gallery" className="w-full block" />
      </>
    )
  }

  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seoTitle : "single-portfolio"}
        description={SEOdata ? SEOdata.seoDescription : ""}
        keywords={SEOdata ? SEOdata.seoKeywords : ""}
        image={SEOdata ? SEOdata.seoImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seoSingleImage : ""}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.imageFormat : ""}
        image_height={SEOdata ? SEOdata.featureImageHeight : ""}
        img_width={SEOdata ? SEOdata.featureImageWidth : ""}
        facebook_url={SEOdata ? SEOdata.facebookPageUrl : ""}
        twitter_page_username={SEOdata ? SEOdata.twitterPageUsername : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitterImageAltTag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitterEstimatedReadingLabel : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitterEstimatedReadingTime : ""}
        page_publish_date={SEOdata ? SEOdata.pagePublishTime : ""}
        page_last_modified_time={SEOdata ? SEOdata.pageLastModifiedTime : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinksSchema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumbSchema : ""}
        logo_schema={Schemadata ? Schemadata.logoSchema : ""}
        organization_schema={Schemadata ? Schemadata.organizationSchema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregateRatingSchema : ""}
        local_business_schema={Schemadata ? Schemadata.localBusinessSchema : ""}
        site_navigation_schema={Schemadata ? Schemadata.siteNavigationSchema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakableSpecificationSchema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang4 : ""}
      />
      <section
        className={`single-portfolio-template dup-${portfolioInfo.slug}`}
      >
        <div className="mt-60 md:mt-65 lg:mt-20 relative">
          <div className="container mx-auto">
            <div className="pt-14 pb-10 lg:pb-20 lg:pt-90 text-center">
              <h2
                className="mb-0 ms:mb-2 lg:mb-2 text-term-primary font-poppings-bold text-rh1 ms:text-rh2 lg:text-h1"
                style={{
                  // color: portfolioInfo.acfPortfolio.portfolioThemeColor,
                }}
              >
                {portfolioInfo.title}
              </h2>
              <p className="text-term-primary font-worksans-normal text-p3 lg:text-rp1 xl:text-p1">
                {portfolioInfo.acfPortfolio.subTitle}
              </p>
            </div>
          </div>
        </div>
        <div
          className="du-profile-info-container"
          dangerouslySetInnerHTML={{ __html: portfolioInfo.content }}
        />
        <div className="du-screens-masonry-main">
          <div className="mb-60 lg:mb-1-20">
            {portfolioInfo.acfPortfolio.portfolioType ===
              "Website Application" &&
              portfolioInfo.otherPages != null &&
              portfolioInfo.otherPages.length > 0 && (
                <div className="pt-12 lg:pt-100 container mx-auto">
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 2 }}
                  >
                    <Masonry
                      className="du-photo-gallery-main"
                      columnsCount={2}
                      gutter="30px"
                    >
                      {portfolioInfo.otherPages.map((pageData, p) => (
                        <div className="du-page-cell" key={p}>
                          {otherPagesGallery(pageData)}
                        </div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </div>
              )}
            {portfolioInfo.acfPortfolio.portfolioType ===
              "Mobile Application" && (
                <div className="mt-12 lg:mt-24 du-mobile-app-all-screens-main">
                  <div className="container mx-auto">
                    <h2 className="du-title du-pd-bottom-10">App All Screens</h2>
                    <div className="du-all-screens-desktop hidden md:block">
                      <GatsbyImage
                        image={getImage(
                          portfolioInfo.acfPortfolio
                            .uploadScreenImageForDesktopView.localFile
                        )}
                        alt={"showcase"}
                      />
                    </div>
                    <div className="du-all-screens-reponsive block md:hidden">
                      <Flickity
                        className={"mobile-screens-carousel"}
                        flickityRef={c => (flkty = c)}
                        elementType={"div"}
                        options={flickityOptions}
                      >
                        {portfolioInfo.uploadScreenImagesForResponsiveView.map(
                          (slide, s) => (
                            <div key={s} className="du-mobile-screen-cell">
                              <img src={slide} alt="DecodeUp Portfolios" />
                            </div>
                          )
                        )}
                      </Flickity>
                    </div>
                  </div>
                </div>
              )}
            {portfolioInfo.acfPortfolio.additionalField && (
              <div
                className="du-profile-addInfo-container"
                dangerouslySetInnerHTML={{
                  __html: portfolioInfo.acfPortfolio.additionalField,
                }}
              />
            )}
          </div>
        </div>
      </section>


      <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
        <div className="container mx-auto">
          <div className="pb-35 lg:pb-36 text-center">
            <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
              Additional Portfolio
            </h3>
          </div>
          <div className="blog-wrap grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-35 sm:gap-x-6-6 md:gap-y-45">
            {morePortfolio.length > 0 && (
              morePortfolio.map(({ node: post }, j) => (
                <div
                  key={j}
                  className={`portfolio-app-main ${post.acfPortfolio.portfolioType === "Mobile Application"
                    ? `portfolio-mobile-app`
                    : post.acfPortfolio.portfolioType === "TV"
                      ? `portfolio-tv-app`
                      : `portfolio-web-app`
                    }`}
                >
                  <Link to={`/portfolio/${post.slug}`} className="block">
                    <div className="du-device-element">
                      <div className="du-elements">
                        <span className="du-elements-speaker du-elements-dots"></span>
                        <span className="du-elements-camera du-elements-dots"></span>
                        <span className="du-elements-dots"></span>
                      </div>
                      <div className="du-elements-switch">
                        <span className="du-elements-switch-lock"></span>
                        <span className="du-elements-switch-vol1"></span>
                        <span className="du-elements-switch-vol2"></span>
                      </div>
                      <div className="du-showcase-feature-img">
                        {post.acfPortfolio.portfolioType ===
                          "Mobile Application" ? (
                          <div className="du-mobile-imgs-main">
                            {post.photosForSlider.length > 0 &&
                              post.photosForSlider.map((image, i) => (
                                <img
                                  key={i}
                                  src={image}
                                  className={`w-full block du-mobile-imges-main  du-mobile-img--${i + 1
                                    }`}
                                />
                              ))}
                          </div>
                        ) : (
                          <div
                            style={{
                              height:
                                post.featuredImage.node.localFile
                                  .childImageSharp.gatsbyImageData.height <
                                  post.featuredImage.node.localFile
                                    .childImageSharp.resize.height
                                  ? post.featuredImage.node.localFile
                                    .childImageSharp.gatsbyImageData.height
                                  : post.featuredImage.node.localFile
                                    .childImageSharp.resize.height,
                            }}
                          >
                            <GatsbyImage
                              image={getImage(
                                post.featuredImage.node.localFile
                              )}
                              alt={"showcase"}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                  <div className="du-showcase-content">
                    <Link to={`/portfolio/${post.slug}`} className="block">
                      <h3 className="pt-6 pb-2 text-p2 xl:text-h3 font-poppings-bold text-term-primary text-left">
                        {post.title}
                      </h3>
                    </Link>
                    <span className="text-p4 text-term-primary font-worksans-normal opacity-70" >
                      {post.portfolioTags.nodes.map(x => x.name).join(", ")}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>
      <LiveChat />
    </Layout>
  )
}
export default PortfolioSinglePage

export const query = graphql`
query singleportfoliopage($id: String!) {
   singlePortfolio: wpPortfolio(id: { eq: $id }) {
    title
    slug
    content
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    otherPages
    uploadScreenImagesForResponsiveView
    acfPortfolio {
      portfolioType
      subTitle
      portfolioThemeColor
      backgroundColor
      additionalField
      uploadScreenImageForDesktopView {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    date(formatString: "MMMM DD, YYYY")
    seoData {
      facebookPageUrl
      featureImageHeight
      featureImageWidth
      fieldGroupName
      imageFormat
      pageLastModifiedTime
      pagePublishTime
      seoDescription
      seoKeywords
      seoTitle
      twitterEstimatedReadingLabel
      twitterEstimatedReadingTime
      twitterImageAltTag
      twitterPageUsername
      schema {
        aggregateRatingSchema
        breadcrumbSchema
        localBusinessSchema
        organizationSchema
        logoSchema
        siteNavigationSchema
        sitelinksSchema
        speakableSpecificationSchema
      }
      hreflang {
        hreflang1
        hreflang2
        hreflang3
        hreflang4
      }
        seoImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        seoSingleImage
    }
  }
  allportf: allWpPortfolio(sort: { order: DESC, fields: [modified] }) {
    edges {
      node {
        title
        acfPortfolio {
          portfolioType
          subTitle
        }
        photosForSlider
        portfolioTags {
          nodes {
            name
            slug
          }
        }
        industries {
          nodes {
          name
          id
          parentId
          }
        }
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
                resize {
                  width
                  height
                }
              }
            }
          }
        }
        seoData {
          seoDescription
          seoKeywords
          seoTitle
        }
      }
    }
  }

}
`